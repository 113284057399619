/* META */
.baseContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
}

#homeContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  width: 55vw;
}

#navbar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 10vh;
  margin-top: 2.5vh;
  width: 95vw;
}

.navbarLink {
  color: #000000;
  margin: 0 5px;
  text-decoration: none;
  transition: all 300ms ease;
}
.navbarLink:hover {
  color: #000000B0;
}

#navbarTitle {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.row {
  align-items: center;
  display: flex;
  width: 100%;
}

.row-plain {
  display: flex;
}
/* END META */

/* BIO */
#bioContainer {
  color: #000000;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
}

#bioFieldwireLink {
  background-color: #FFCD11;
  border-radius: 5px;
  color: #000000;
  padding: 5px 10px;
  text-decoration: none;
}

#bioText {
  line-height: 2em;
  margin: 0;
}

/* MOBILE ADJUSTMENTS */
@media(max-width: 576px) {
  #bioFieldwireLink {
    padding: 3px 7px;
  }
  #homeContainer {
    width: 80vw;
  }
  #navbar {
    margin-top: 0;
    width: 90vw;
  }
}
